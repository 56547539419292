<template lang="pug">
    div
</template>

<script lang="ts">
import Vue from 'vue'
import ApplicationService from '../services/ApplicationService'
export default Vue.extend({
    
    async mounted() {
        const oldId = this.$route.params.oldId

        if(oldId) {
            const id = await ApplicationService.getRequestFromOldId(oldId)
            this.$router.push('/afi/request/show/' + id)
        } else {
            this.$router.push('/')
        }
    }
})
</script>

<style lang="scss" scoped>

</style>